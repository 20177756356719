import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './nav-styles.css'
const NavComp = () => {
  const [expanded, setExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      
      if (currentScrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  const handleNavLinkClick = (path, hash) => {
    navigate(path);
    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
    setExpanded(false);
  };

  return (
    <Navbar 
      style={{ 
        transform: isVisible ? "translateY(0)" : "translateY(-100%)"
      }} 
      expand="lg" 
      className={`fixed-top ${expanded ? 'expanded-nav' : ''}`}
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src="images/logo-complete.png"
            height="63"
            width="112"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link onClick={() => handleNavLinkClick("/", "#")}>
              Inicio
            </Nav.Link>
            <Nav.Link onClick={() => handleNavLinkClick("/", "services")}>
              Servicios
            </Nav.Link>
            <Nav.Link onClick={() => handleNavLinkClick("/", "reviews")} className="d-md-block d-none">
              Testimonios
            </Nav.Link>
            <Nav.Link onClick={() => handleNavLinkClick("/", "contact")}>
              Contacto
            </Nav.Link>
            <Button
              variant="dark"
              onClick={() => handleNavLinkClick("/", "contact")}
              className="d-lg-none mt-3"
            >
              Agenda tu cita
            </Button>
          </Nav>
        </Navbar.Collapse>
        <Button
          variant="dark"
          onClick={() => handleNavLinkClick("/", "contact")}
          className="d-none d-lg-block"
        >
          Agenda tu cita
        </Button>
      </Container>
    </Navbar>
  );
};

export default NavComp;