import React, { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import './hero.css'; // Ensure this CSS file is correctly imported

const Hero = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videoCardRef = useRef(null);

  const handlePlayButtonClick = () => {
    setIsVideoPlaying(true);
    document.getElementById('hero-video').play();
  };

  const handleClickOutside = (event) => {
    if (videoCardRef.current && !videoCardRef.current.contains(event.target)) {
      setIsVideoPlaying(false);
      document.getElementById('hero-video').pause();
    }
  };

  useEffect(() => {
    if (isVideoPlaying) {
      document.addEventListener('click', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside, true);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isVideoPlaying]);

  return (
    <div id="#">
      {/* Mobile version */}
      <div className="position-relative w-100 vh-100 overflow-hidden mb-5 d-md-none">
        <video
          id="myVideo"
          className="d-block position-absolute top-0 start-0 w-100 h-100 object-fit-cover"
          src="images/hero-mobile-video.mp4"
          width={'100%'}
          height={'100%'}
          autoPlay
          loop
          playsInline
          muted
          controls
        >
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Desktop version */}
      <section className="hero-section d-none d-md-block mt-5">
        <Row className="d-flex justify-content-center align-items-center w-100 m-0 px-5 video-container">
          <img
            className="hero-image p-5 mt-3"
            src="images/hero-image.png"
            alt="Hero"
          />
          <div ref={videoCardRef} className={`video-card ${isVideoPlaying ? 'playing' : ''}`}>
            {!isVideoPlaying && (
              <>
                <img className="video-placeholder" src="images/placeholder-video.png" alt="Placeholder" />
                <button className="play-button" onClick={handlePlayButtonClick}>
                  <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.54215 5.14596C5.86127 4.73773 5 5.23424 5 6.03497V29.965C5 30.7657 5.86127 31.2623 6.54215 30.8541L26.4994 18.8891C27.1669 18.4888 27.1669 17.5112 26.4994 17.1109L6.54215 5.14596Z" fill="white" stroke="white" strokeWidth="9" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </>
            )}
            <video id="hero-video" src="images/hero-video.mp4" controls style={{ display: isVideoPlaying ? 'block' : 'none' }} />
          </div>
        </Row>
      </section>
    </div>
  );
};

export default Hero;
