import React from "react";
import { Row, Col } from "react-bootstrap";
import "./about-us-section.css"; // Import the custom CSS file

const AboutUsSection = () => {
  return (
    <section className="h-50 d-flex justify-content-center align-items-center my-5 pt-5 position-relative">
      <div className="image-container overflow-hidden">
        <img src="/images/blur.png" alt="Background Blur" className="w-100" />
      </div>
        <Row className="d-flex justify-content-center align-items-center px-6 ">
          <Col md={12} className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-start">
              <h2 className="fw-lighter me-4 ms-md-0 ms-4 title-tu-team" >Tu Team</h2>
              <div className="top-border"></div>
            </div>
            <p className="main-text py-md-5 py-4">
              Somos un{" "}
              <span className="gradient-text pe-3" style={{ fontWeight: "500" }}>
                grupo de profesionales
              </span> 
              en telemarketing, desarrollo de embudos de conversión, guiones de venta,  pautas digitales de alcance masivo y ventas high ticket.
            </p>
            
            <div className=" d-flex justify-content-end me-4 me-md-0">
              <div className="bottom-border"></div>
              
            </div>
          </Col>
        </Row>
    </section>
  );
};

export default AboutUsSection;
