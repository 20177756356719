import Footer from './Footer';
import NavComp from './Navbar';

const Layout = ({ children } ) => {
    return (
        <div className='overflow-hidden'>
            <NavComp />
            {children}
            <Footer />
        </div>
    );
}

export default Layout;