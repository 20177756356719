import React from "react";
import "./styles.css";
import { Button } from "react-bootstrap";

const CallToAction = () => {
  return (
    <section id="CallToAction" className="action-container">
      <div className="bg-blur-right-call w-75 d-md-block d-none">
        <img src="images/blur-v2.png" alt="blur-bg" />
      </div>
      <div className="image-right2 w-100  d-md-block d-none">
        <img src="images/call-image1.png" alt="blur-bg" style={{width: '20%'}} />
      </div>
      <div className="image-top w-100  d-md-block d-none">
        <img src="images/call-image2.png" alt="blur-bg" style={{width: '25%'}} />
      </div>
      <div className="image-bot w-100  d-md-block d-none">
        <img src="images/call-image3.png" alt="blur-bg" style={{width: '20%'}} />
      </div>
      <div className="flex-column justify-content-center align-items-center h-100 mt-5 px-5 d-md-flex d-none">
        <h1 className="fst-italic fw-bold fs-10 heading-h1 text-center">
          ¿Listo para{" "}
          <span className="gradient-text fst-normal">triplicar tus ventas</span>{" "}
          en 60 días?
        </h1>
        <p className="fs-3 mt-5 heading-p">Agenda tu cita de 30 minutos gratis</p>
        <Button variant="dark" size="lg" 
        className="px-5 mt-3 py-md-2"
        onClick={() =>
          document
            .getElementById("contact")
            .scrollIntoView({ behavior: "smooth" })
        }
        >
          Agenda tu cita{" "}
          <span>
            <svg
              width="26"
              height="20"
              viewBox="0 0 26 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 1.25L24.25 10M24.25 10L15.5 18.75M24.25 10L1.75 10"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </Button>
      </div>
      <div className="d-block d-md-none px-4 my-5">
        <h1 className="fst-italic fw-bold heading-h1 text-center">
          ¿Listo para{" "}
          <span className="gradient-text fst-normal">triplicar tus ventas</span>{" "}
          <span className="fst-normal">en</span>{" "}
          
           60 días?
        </h1>
        <p className="fs-3 mt-5 heading-p ">Agenda tu cita de 30 minutos gratis</p>
        <Button variant="dark"
        size="lg" 
        className="w-100"
        onClick={() =>
          document
            .getElementById("contact")
            .scrollIntoView({ behavior: "smooth" })
        }
        >
          Agenda tu cita{" "}
          <span>
            <svg
              width="26"
              height="20"
              viewBox="0 0 26 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5 1.25L24.25 10M24.25 10L15.5 18.75M24.25 10L1.75 10"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </Button>
      </div>
    </section>
  );
};

export default CallToAction;
