import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css"; // Import the custom CSS file

const ServiceCarousel = () => {
  const images = [
    {
      src: "images/service1.png",
      caption: "Sabes que estás perdiendo ventas porque no tienes un guión",
    },
    {
      src: "images/service2.png",
      caption: "Estás gastando tu dinero en anuncios que no generan ventas",
    },
    {
      src: "images/service3.png",
      caption:
        "No tienes tiempo para contestar a todos los clientes potenciales",
    },
    {
      src: "images/service4.png",
      caption: "Tu producto vale más de 500 dólares y no sabes cómo venderlo",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
  };

  let sliderRef = React.useRef(null);

  const nextSlide = () => {
    sliderRef.slickNext();
  };

  const prevSlide = () => {
    sliderRef.slickPrev();
  };

  return (
    <section className="px-8 py-5" id="services">
      <h2 className="fw-lighter fst-italic px-4 px-md-0">
        Estás aqui{" "}
        <span className="gradient-text fw-bold fst-normal">por qué</span>
      </h2>
      <div className="carousel-container d-none d-md-block">
        <Slider ref={(c) => (sliderRef = c)} {...settings}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-item ${
                currentSlide === index ? "last-visible" : ""
              }`}
            >
              <div className="carousel-image-wrapper">
                <img
                  src={image.src}
                  alt={`Service ${index + 1}`}
                  className="carousel-image"
                />
              </div>
              <div className="carousel-caption text-start fs-4">
                {image.caption}
              </div>
            </div>
          ))}
        </Slider>
        <div className="carousel-controls">
          <button className="carousel-control prev" onClick={prevSlide}>
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.8333 39.5833L6.25 25M6.25 25L20.8333 10.4166M6.25 25L43.75 25"
                stroke="#5500FF"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button className="carousel-control next" onClick={nextSlide}>
            <svg
              width="42"
              height="34"
              viewBox="0 0 42 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.1667 2.41663L39.75 17M39.75 17L25.1667 31.5833M39.75 17L2.25 17"
                stroke="#5500FF"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="image-container2">
          <img src="/images/blur2.png" alt="Background Blur" />
        </div>
      </div>
      <div className="mobile-images d-md-none px-md-0 px-4 mt-5">
        {images.map((image, index) => (
          <div key={index} className="mobile-image-container">
            <div className="carousel-image-wrapper">
              <img
                src={image.src}
                alt={`Service ${index + 1}`}
                className="carousel-image"
              />
            </div>
            <div className="carousel-caption text-start fs-4">
              {image.caption}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceCarousel;
