import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { AppRouter } from './routes/AppRouter';

export default function App() {
  return (
      <AppRouter />
  );
}

