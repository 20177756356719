import React from 'react';
import PropTypes from 'prop-types'
import './ThanksComponent.css';

const ThanksComponent = ({ isConfirmed }) => {
    const content = {
        text: isConfirmed ? 'Tu cita está confirmada.' : 'Elige la Fecha y Hora de nuestra reunión.',
        icon: {
            src: isConfirmed ? 'images/check-dark-icon.svg' : 'images/calendar-dark-icon.svg',
            alt: isConfirmed ? 'check' : 'bell',
            dimensions: isConfirmed ? { width: '28px', height: '28px' } : { width: '20px', height: '20px' }
        }
    };

    return (
        <div className="card-container mt-2">
            <div className='card-img-container'>
                <img src="images/heroGraciasPorAgendar.png" alt="thanks card" className='card-img'/>
            </div>

            <div className= {'card-chip ' + (isConfirmed ? 'card-chip-green' : 'card-chip-white')} >
                <img src={content.icon.src} alt={content.icon.alt} style={content.icon.dimensions} />
                <p className='card-chip-text'>{content.text}</p>
            </div>
        </div>
    );
};

export default ThanksComponent;

ThanksComponent.propTypes = {
    isConfirmed: PropTypes.bool.isRequired,
  };