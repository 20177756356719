/**
 * Formats a given date object to a string in the format "HH:MM AM/PM".
 *
 * @param {Date} date - The date object to format.
 * @return {string} The formatted date string in the format "HH:MM AM/PM".
 * @throws {TypeError} Will throw an error if the argument is not a valid Date object.
 */
export const formatDateToHHMMString = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid Date object');
    }

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    // Convert from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours || 12; // Hour '0' should be '12'

    // Add a leading zero to minutes if needed
    const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutesFormatted} ${ampm}`;
}

/**
 * Formats a given date to a string in the format DD MMMM YYYY.
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date string.
 * @throws {TypeError} Will throw an error if the argument is not a valid Date object.
 */
export const formatDateToDDMMYYYYString = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid Date object');
    }

    const formatter = new Intl.DateTimeFormat('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
    });
    return formatter.format(date);
}

/**
 * Formats a given date to a string in the format YYYY-MM-DD.
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted date string.
 * @throws {TypeError} Will throw an error if the argument is not a valid Date object.
 */
export const formatDateToYYYYMMDD = (date, days = 0) => { // Asegúrate de definir un valor predeterminado para `days`

    if (!(date instanceof Date) || isNaN(date.getTime())) { // Usa `getTime()` para validar el objeto Date
        throw new TypeError('Invalid Date object');
    }

    // Modifica la fecha y crea una nueva instancia de Date con el valor modificado
    const modifiedDate = new Date(date);
    modifiedDate.setDate(modifiedDate.getDate() + days);

    const year = modifiedDate.getFullYear();
    const month = String(modifiedDate.getMonth() + 1).padStart(2, '0');
    const day = String(modifiedDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


/**
 * Formats a given date to a string in the format HH:MM.
 *
 * @param {Date} date - The date to be formatted.
 * @return {string} The formatted time string.
 * @throws {TypeError} Will throw an error if the argument is not a valid Date object.
 */
export const formatTimeToHHMM = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid Date object');
    }

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
}

/**
 * Adds a specified number of hours to a given date and returns the updated date.
 *
 * @param {Date} date - The original date to which hours will be added.
 * @param {number} add - The number of hours to add to the date.
 * @return {Date} The updated date with the added hours.
 * @throws {TypeError} Will throw an error if the argument is not a valid Date object.
 */
export const addHours = (date, add) => {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid Date object');
    }

    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + add);
    return newDate;
}

export const addDaysToDate = (date, days) => {
    const result = new Date(date);
    
    result.setDate(result.getDate() + days);
    return result;
}

export const formatDateToUTC = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
        throw new TypeError('Invalid Date object');
    }

    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
    const year = String(date.getUTCFullYear()).slice(-2);

    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12;
    hours = hours ? hours : 12; // La hora '0' debería ser '12'
    const formattedHours = String(hours).padStart(2, '0');

    const timezoneOffset = date.getTimezoneOffset();
    const offsetHours = Math.abs(Math.floor(timezoneOffset / 60));
    const offsetMinutes = Math.abs(timezoneOffset % 60);
    const sign = timezoneOffset > 0 ? '-' : '+';
    const utcOffset = `UTC${sign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

    return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm} (${utcOffset})`;
}