// src/context/StatusContext.js
import React, { createContext, useState, useContext } from 'react';

const StatusContext = createContext();

export const StatusProvider = ({ children }) => {
    const [status, setStatus] = useState('no-data-to-add');

    return (
        <StatusContext.Provider value={{ status, setStatus, STATUS: {
            NO_DATA_TO_ADD_LEAD: 'no-data-to-add',
            DATA_TO_ADD_LEAD: 'data-to-add',
        } }}>
            {children}
        </StatusContext.Provider>
    );
};

export const useStatus = () => useContext(StatusContext);
