import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./styles.css";
const EnfoqueSection = () => {
  return (
    <div className="position-relative d-md-block d-none">
      <section
        className="my-5 px-8 d-flex align-items-center justify-content-center position-relative"
        style={{ height: "80vh" }}
      >
        <Row className="position-relative">
          <Col md={6}>
            <div className="w-100 pe-5">
              <img
                src="images/enfoque-image.png"
                alt="Enfoque section"
                className="rounded-5 w-100"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex flex-column px-ls-5">
              <img src="images/logo.png" alt="TuTeam Logo" className="w-25" />
              <h1 className="mb-5" style={{ fontSize: "4em" }}>
                <span className="fst-italic fw-lighter text-gray-600">
                  Nuestro
                </span>{" "}
                <span className="gradient-text">enfoque</span>
              </h1>
              <p className="fs-4">
                Nosotros ponemos la inversión publicitaria, los anuncios y el
                equipo de ventas. Cobramos solo por comisión.
              </p>
              <p className="mt-3 fs-4">
                Si nos gusta tu negocio,
                <span className="fw-semibold">invertimos en ti.</span>
              </p>
            </div>
          </Col>
        </Row>
      </section>
      <div className="position-absolute bottom-0 start-50 translate-middle-x d-flex">
        <Card className="card-bg p-4 position-relative rounded-5 border-0 me-5">
          <div className="card-content">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.3637 17.4831C29.9066 18.1088 30.854 18.1759 31.4797 17.633C32.1054 17.09 32.1725 16.1426 31.6296 15.5169L29.3637 17.4831ZM18.6364 30.5169C18.0934 29.8912 17.1461 29.8241 16.5204 30.367C15.8946 30.91 15.8275 31.8574 16.3705 32.4831L18.6364 30.5169ZM25.5 11.5C25.5 10.6716 24.8284 10 24 10C23.1716 10 22.5 10.6716 22.5 11.5H25.5ZM22.5 36.5C22.5 37.3284 23.1715 38 24 38C24.8284 38 25.5 37.3285 25.5 36.5L22.5 36.5ZM45 24C45 35.598 35.598 45 24 45V48C37.2548 48 48 37.2548 48 24H45ZM24 45C12.402 45 3 35.598 3 24H0C0 37.2548 10.7452 48 24 48V45ZM3 24C3 12.402 12.402 3 24 3V0C10.7452 0 0 10.7452 0 24H3ZM24 3C35.598 3 45 12.402 45 24H48C48 10.7452 37.2548 0 24 0V3ZM24 22.5C22.1838 22.5 20.6073 22.0065 19.5287 21.2875C18.4397 20.5614 18 19.7231 18 19H15C15 21.0383 16.2392 22.7 17.8646 23.7836C19.5005 24.8742 21.6741 25.5 24 25.5V22.5ZM18 19C18 18.2769 18.4397 17.4386 19.5287 16.7125C20.6073 15.9935 22.1838 15.5 24 15.5V12.5C21.6741 12.5 19.5005 13.1258 17.8646 14.2164C16.2392 15.3 15 16.9617 15 19H18ZM24 15.5C26.4661 15.5 28.4245 16.4007 29.3637 17.4831L31.6296 15.5169C29.9752 13.6103 27.086 12.5 24 12.5V15.5ZM24 25.5C25.8162 25.5 27.3927 25.9935 28.4713 26.7125C29.5604 27.4386 30.0001 28.2769 30.0001 29H33.0001C33.0001 26.9617 31.7608 25.3 30.1354 24.2164C28.4995 23.1258 26.3259 22.5 24 22.5V25.5ZM22.5 11.5V14H25.5V11.5H22.5ZM22.5001 34L22.5 36.5L25.5 36.5L25.5001 34L22.5001 34ZM24.0001 32.5C21.534 32.5 19.5756 31.5993 18.6364 30.5169L16.3705 32.4831C18.0248 34.3897 20.9141 35.5 24 35.5L24.0001 32.5ZM30.0001 29C30.0001 29.7231 29.5604 30.5614 28.4713 31.2875C27.3927 32.0065 25.8163 32.5 24.0001 32.5V35.5C26.326 35.5 28.4995 34.8742 30.1354 33.7836C31.7608 32.7 33.0001 31.0383 33.0001 29H30.0001ZM22.5 14L22.5001 34L25.5001 34L25.5 14L22.5 14Z"
                fill="#330099"
              />
            </svg>
            <h3 className="fw-normal mt-3">
              <span>No pagas mensualidades.</span>
            </h3>
          </div>
        </Card>
        <Card className="card-bg p-4 position-relative rounded-5 border-0 me-5">
          <div className="card-content">
            <svg
              width="48"
              height="28"
              viewBox="0 0 48 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.5 1.5H46.5M46.5 1.5V21.5M46.5 1.5L26.5 21.5L16.5 11.5L1.5 26.5"
                stroke="#330099"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h3 className="fw-normal mt-3">
              <span>Ganas más y arriesgas menos.</span>
            </h3>
          </div>
        </Card>
        <Card className="card-bg p-4 position-relative rounded-5 border-0 ">
          <div className="card-content">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5 8.70546V42.1004C21.5 44.5303 19.5303 46.5 17.1004 46.5C15.2411 46.5 13.5826 45.3312 12.9572 43.5802L7.59118 28.2072M39 26.5C43.1421 26.5 46.5 23.1421 46.5 19C46.5 14.8579 43.1421 11.5 39 11.5M7.59118 28.2072C4.01074 26.6852 1.5 23.1358 1.5 19C1.5 13.4771 5.97714 9 11.5 9H16.0802C26.3318 9 35.1422 5.91476 39 1.5L39 36.5C35.1422 32.0852 26.3318 29 16.0802 29L11.4999 29C10.113 29 8.79191 28.7176 7.59118 28.2072Z"
                stroke="#330099"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h3 className="fw-normal mt-3">
              <span>Mejora tu producto, nosotros mejoramos tus ventas.</span>
            </h3>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default EnfoqueSection;
