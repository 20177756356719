import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();

    const handleNavLinkClick = (path, hash) => {
        navigate(path);
        setTimeout(() => {
            const element = document.getElementById(hash);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }, 100);
    };

    return (
        <footer className="bg-transparent py-md-5 pb-5 mt-md-5">
            <Container>
                {/* Original layout for larger screens */}
                <div className="d-none d-md-flex flex-column justify-content-center align-items-center">
                    <Row className="mt-5">
                        <Col>
                            <img src="images/logo-complete.png" alt="TuTeam Logo" className="mb-3 ms-5" style={{ maxWidth: '70%' }} />
                        </Col>
                    </Row>
                    <Row className="my-4 fs-5">
                        <Col>
                            <Nav className="align-items-center ">
                                <Nav.Item>
                                    <Nav.Link className='text-dark' onClick={() => handleNavLinkClick("/", "#")}>Inicio</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark' onClick={() => handleNavLinkClick("/", "services")}>Servicios</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark' onClick={() => handleNavLinkClick("/", "reviews")}>Testimonios</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark' onClick={() => handleNavLinkClick("/", "contact")}>Contacto</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </div>

                {/* Mobile layout */}
                <div className="d-md-none">
                    <Row className="mb-4 text-center">
                        <Col>
                            <img src="images/logo.png" alt="TuTeam Logo" className="mb-3" style={{ maxWidth: '30%' }} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <Nav className="flex-column align-items-center text-dark">
                                <Nav.Item>
                                    <Nav.Link className='text-dark fs-4' onClick={() => handleNavLinkClick("/", "#")}>Inicio</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark fs-4' onClick={() => handleNavLinkClick("/", "services")}>Servicios</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark fs-4' onClick={() => handleNavLinkClick("/", "reviews")}>Testimonios</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className='text-dark fs-4' onClick={() => handleNavLinkClick("/", "contact")}>Contacto</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                </div>

                {/* Social media icons (same for both layouts) */}
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                                <img src="images/instagram.png" alt="Instagram" style={{ width: '50px', height: '50px' }} />
                            </a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                                <img src="images/facebook.png" alt="Facebook" style={{ width: '50px', height: '50px' }} />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="mx-2">
                                <img src="images/twitter.png" alt="Twitter" style={{ width: '50px', height: '50px' }} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
