import Hero from '../components/Hero/Hero';
import ContactSection from '../components/Contact/Contact';
import AboutUsSection from '../components/AboutUs/AboutUs';
import ServiceCarousel from '../components/ServiceCarousel/ServiceCarousel';
import ServiceSection from '../components/ServiceSection/ServiceSection';
import EnfoqueSection from '../components/Enfoque/EnfoqueSection';
import Reviews from '../components/Reviews/Reviews';
import CallToAction from '../components/CallToAction/CallToAction';
import EnfoqueSectionMobile from '../components/Enfoque/EnfoqueSectionMobile';
import  ConfirmCitationComponent from '../components/ConfirmCitation/ConfirmCitationComponent';

// export  { default as Hero } from '../Hero/Hero';
// export  { default as ContactSection } from '../Contact/Contact';
// export  { default as AboutUsSection } from '../AboutUs/AboutUs';
// export  { default as asServiceCarousel } from '../ServiceCarousel/ServiceCarousel';
// export  { default as ServiceSection } from '../ServiceSection/ServiceSection';
// export  { default as EnfoqueSection } from '../Enfoque/EnfoqueSection';
// export  { default as Reviews } from '../Reviews/Reviews';
// export  { default as CallToAction } from '../CallToAction/CallToAction';
// export  { default as EnfoqueSectionMobile } from '../Enfoque/EnfoqueSectionMobile';
// export  { default as Layout } from '../shared/Layout';
// export  { default as ConfirmCitationPage } from '../ConfirmCitation/ConfirmCitationPage';

export const HomePage= () => {
    return (
        <>
            <Hero />
            <ContactSection />
            <AboutUsSection />
            <ServiceCarousel />
            <ServiceSection />
            <EnfoqueSection />
            <EnfoqueSectionMobile />
            <Reviews />
            <CallToAction />
        </>
    );
}

export const CitacionPage = () => {
    return (
        <ConfirmCitationComponent />
    );
}
