import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from '../components/shared/Layout';
import {HomePage, CitacionPage} from "../pages";
// import {  useStatus } from '../context/StatusContext';

export const AppRouter = () => {
    // const { status, STATUS } = useStatus();
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='*' element={<Navigate to='/' replace />} />
                    <Route path='citacion' element={<CitacionPage />} />
                    {/* { 
                        status === STATUS.DATA_TO_ADD_LEAD && <Route path='citacion' element={<CitacionPage />} />     
                        
                    } */}
                </Routes>
            </Layout>
        </BrowserRouter>
    );
}