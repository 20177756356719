import PropTypes from 'prop-types';
import { atcb_action } from "add-to-calendar-button-react";
import { useEffect, useMemo, useRef } from 'react';
import {
    formatDateToYYYYMMDD,
    formatTimeToHHMM,
    addHours
} from '../../../utils/dateUtils';

const ButtonAddToCalendarComponent = ({ date }) => {

    const calendarEvent = useMemo(() => ({
        name: 'Cita con Somos Tu Team',
        startDate: formatDateToYYYYMMDD(date, 0),
        startTime: formatTimeToHHMM(date),
        endDate: formatDateToYYYYMMDD(date, 7),
        endTime: formatTimeToHHMM(addHours(date, 8)),
        location: "Somos Tu Team",
        description: "Cita con Somos Tu Team",
        recurrence_count: 5,
        options: ["Apple", "Google", "iCal", "Outlook.com", "Yahoo", "Microsoft365"],
        timeZone: "America/Bogota",
        listStyle: "overlay",
        language: "es",
        lightMode: "dark"
    }), [date]);

    const buttonRef = useRef(null);

    useEffect(() => {
        const button = buttonRef.current;
        if (button) {
            const handleClick = () => atcb_action(calendarEvent, button);
            button.addEventListener('click', handleClick);

            // Cleanup to remove the event listener when the component is unmounted
            return () => {
                button.removeEventListener('click', handleClick);
            };
        }
    }, [calendarEvent]);

    return (
        <button
            id='atcb-add-to-calendar'
            ref={buttonRef}
            type='button'
            className='btn btn-dark'>
            <img
                src="images/calendar-light-icon.svg"
                alt="calendar"
                className='icon' />Agendar a mi calendario
        </button>
    );
};

export default ButtonAddToCalendarComponent;

ButtonAddToCalendarComponent.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
};