import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Button } from "react-bootstrap";
import "./styles.css"; // Import the custom CSS file

const ServiceSection = () => {
  const sliderRef = useRef(null);

  const images = [
    {
      src: "images/service2-1.png",
      caption: "Telemarketing y WhatsApp",
    },
    {
      src: "images/service2-2.png",
      caption: "Desarrollo de embudos de conversión",
    },
    {
      src: "images/service2-4.png",
      caption: "Pautas digitales de alcance masivo",
    },
  ];

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    centerMode: true,
    centerPadding: "5%", // Adjust this value to show half of the next slide
    // beforeChange: (oldIndex, newIndex) => {
    //   setCurrentSlide(newIndex);
    // },
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  const prevSlide = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className="position-relative my-md-5 mb-5 px-8">
      <Row className="custom-row py-5 d-flex justify-content-between align-items-center">
        <Col md={6} className="p-0 custom-text-col">
          <p className="custom-text mb-0 px-md-0 px-4 lh-1">
            No somos un costo fijo, somos una{" "}
            <span className="fw-bold">venta fija.</span>
          </p>
        </Col>
        <Col
          md={6}
          className="p-md-0 d-md-flex justify-content-end mt-md-0 mt-3 custom-button-col px-4"
        >
          <Button variant="dark" 
          className="custom-button"
          onClick={() =>
            document
              .getElementById("contact")
              .scrollIntoView({ behavior: "smooth" })
          }
          >
            Agenda tu cita
          </Button>
        </Col>
      </Row>
      <Row className="card-background p-5 position-relative overflow-visible">
        <Col
          md={6}
          className="d-flex flex-column justify-content-between mb-4 mb-md-0"
          style={{ height: "100%" }}
        >
          <h2 className="align-items-start mb-3 fw-light">
            Nuestros servicios
          </h2>
          <div className="mt-auto mb-5">
            <p className="custom-p d-md-block d-none">
              <span className="fst-italic text-gray-600">
                Un abanico de soluciones
              </span>{" "}
              <span className="fw-semibold">
                para resolver sus problemas de manera
              </span>{" "}
              <span className="gradient-text fw-semibold">
                personalizada y efectiva
              </span>
              .
            </p>
            <p className="custom-p d-md-none d-block">
              <span className="fst-italic text-gray-600">
                Un abanico de soluciones
              </span>{" "}
              <span style={{fontWeight: '600'}} className="tex-gray-600">
                para resolver sus problemas de manera
              </span>{" "}
              <span className="fw-bold text-black">
                personalizada y efectiva
              </span>
              .
            </p>
          </div>
        </Col>
        <Col
          md={6}
          className="slider-col px-0 position-relative"
          style={{ height: "100%" }}
        >
          <div className="slider-container">
            <div className="slider-container slider-container-2">
              <div className="slider-container slider-container-2">
                <Slider ref={sliderRef} {...settings}>
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="position-relative slick-slide-2"
                    >
                      <div className="carousel-image-wrapper2 position-relative">
                        <img
                          src={image.src}
                          alt={`Service ${index + 1}`}
                          className="h-100 w-100"
                        />
                      </div>
                      <div className="carousel-caption2 fw-bold text-center">
                        {image.caption}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </Col>
        <div
          className="carousel-controls position-absolute"
        >
          <Button
            variant="link"
            className="carousel-control prev"
            onClick={prevSlide}
          >
            <svg
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.8333 39.5833L6.25 25M6.25 25L20.8333 10.4166M6.25 25L43.75 25"
                stroke="#5500FF"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
          <Button
            variant="link"
            className="carousel-control next"
            onClick={nextSlide}
          >
            <svg
              width="42"
              height="34"
              viewBox="0 0 42 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M25.1667 2.41663L39.75 17M39.75 17L25.1667 31.5833M39.75 17L2.25 17"
                stroke="#5500FF"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default ServiceSection;
