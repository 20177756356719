import { useState } from "react";
import ThanksComponent from "./Thank/ThanksComponent";
import ScheduleComponent from "./Schedule/ScheduleComponent";
import CitationComponent from "./Citation/CitationComponent";
import { useLocation } from "react-router-dom";
import { FetchAddLead } from "./Addlead";
import { addDaysToDate } from "../../utils/dateUtils";
import "./ConfirmCitationComponent.css";

const ConfirmCitationComponent = () => {
    
    const [isConfirmed, setIsConfirmed] = useState(false);// TODO --> set false default
    const [date, setDate] = useState(addDaysToDate(new Date(), 1));
    
    const location = useLocation();
    const { nombreApellido, email, paginaWebEmpresa, redesSocialesEmpresa, phone } = location.state || {};

    const updateIsConfirmed = async () => {
        const isOk = await FetchAddLead(nombreApellido, email, date, paginaWebEmpresa, redesSocialesEmpresa, phone );
        console.log(isOk);
        setIsConfirmed(isOk);
    };





    return (
        <section className="d-flex flex-column align-items-center justify-content-center px-md-5 mt-md-5 pt-md-5 px-4 my-5  pt-3 p-sm-5 pb-0">
            
            <ThanksComponent isConfirmed={isConfirmed} />
            <div className="section d-flex w-100 justify-content-center">
                {
                    isConfirmed 
                    ? <CitationComponent
                            name={nombreApellido}
                            date={date} /> 
                    : <ScheduleComponent
                            name={nombreApellido}
                            date={date}
                            onUpdateDate={setDate}
                            onUpdateIsConfirmed={updateIsConfirmed} />
                }
            </div>
            <div className="image-c overflow-hidden">
                <img src="/images/blur.png" alt="Background Blur" className="w-100" />
            </div>
            <div className="image-c-second">
                <img src="/images/blur2.png" alt="Background Blur" />
            </div>
        </section>
    );
};

export default ConfirmCitationComponent;