import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './ScheduleComponent.css';

const ScheduleComponent = ({ onUpdateIsConfirmed, onUpdateDate, date }) => {

     /**
     * Handles changes to the selected date.
     *
     * @param {Date} date - The newly selected date.
     * @return {void} No return value.
     */
    const handleDateChange = (date) => {
        onUpdateDate(date);
    };

     /**
     * Handles form submission by preventing default behavior and confirming the citation.
     *
     * @param {Event} event - The form submission event.
     * @return {void} No return value.
     */
    const handleSubmit = (event) => {
        event.preventDefault();
        onUpdateIsConfirmed();
    };
    
    return (
        <div className="schedule-card">
            <form className="schedule-form" onSubmit={handleSubmit}>
                <div className='schedule-inputs'>
                    <div>Fecha <DatePicker
                        selected={date}
                        onChange={handleDateChange}
                        excludeDates= {[new Date()]}
                        minDate={new Date()} className='date-picker-custom' /></div>

                    <div>Hora <DatePicker
                        selected={date}
                        onChange={handleDateChange}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Hora"
                        dateFormat="h:mm aa" className='date-picker-custom' /></div>
                </div>

                <button type="submit" className="btn btn-dark">Confirmar</button>
            </form>
        </div>
    );
};

export default ScheduleComponent;

ScheduleComponent.propTypes = {
    onUpdateIsConfirmed: PropTypes.func.isRequired,
    onUpdateDate: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
  };