import PropTypes from 'prop-types';
import './CitationComponent.css';
import {
    formatDateToHHMMString,
    formatDateToDDMMYYYYString,
} from '../../../utils/dateUtils';
import ButtonAddToCalendarComponent from '../ButtonAddToCalendar/ButtonAddToCalendarComponent';

const CitationComponent = ({ name, date }) => {


    const formatShortName = (sentence, length) => {
        const words = sentence.split(/\s+/);
        let totalLength = 0;
        let resultWords = [];

        for (const word of words) {
            if (totalLength + word.length + resultWords.length <= length) {
                resultWords.push(word);
                totalLength += word.length;
            } else {
                break;
            }
        }

        return resultWords.join(' ');
    }

    const validDate = (date instanceof Date && !isNaN(date)) ? date : new Date();

    return (
        <div className='citation-card'>
            <div className='citation-section white-card'>
                <div className='white-card-header '>
                    <h1 className='citation-title'>
                        <em className='font-italic'> Detalles</em> <br /> de la Cita
                    </h1>
                    <h1 className='citation-title-mobile'>Detalles de la Cita</h1>
                    <div className='citation-date-text'>
                        <p><b>{formatShortName(name, 27)}</b></p>
                        <p><b>Fecha:</b> {formatDateToDDMMYYYYString(validDate)}</p>
                        <p><b>Hora:</b> {formatDateToHHMMString(validDate)}</p>
                    </div>
                </div>

                <div className='citation-date-text-mobile'>
                    <p>{name}</p>
                    <p> {formatDateToDDMMYYYYString(validDate)}. A las {formatDateToHHMMString(validDate)}</p>
                </div>

                <div className='citation-sm-text m-0 p-0'>
                    Por favor, asegúrate de estar disponible unos minutos antes de la hora <br /> programada para que podamos comenzar puntualmente.
                </div>
            </div>
            <div className='citation-section citation-section-agenda'>
                <h1 className='citation-title'>Agenda y Síguenos</h1>
                <div className='citation-text'>
                    Agenda tu cita en tu calendario y <br /> encuéntranos en las redes sociales.
                </div>
                <div className='citation-buttons'>
                    <ButtonAddToCalendarComponent date={validDate} />
                    <a className='btn btn-outline-dark bg-white' href='https://www.instagram.com/tuteamagency' target='_blank' rel='noopener noreferrer'>
                        <img src="images/instagram-light-icon.svg" alt="instagram icon light" className='icon' />Síguenos en Instagram
                    </a>
                </div>
            </div>
        </div>
    );
};

export default CitationComponent;

CitationComponent.propTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date),
};